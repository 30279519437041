<template>
  <div>
    <div v-if="isSearchModalOpen" class="search-modal" :style="modalTopHeight">
      <CrossIcon
        class="filters-sidebar__top-icon search-modal-close-icon"
        @click="toggleSearchModal"
      />

      <SearchBarCustom
        ref="searchBarRef"
        class="sf-header__search sf-search-bar"
        :value="term"
        @keydown="handleSearch($event)"
        @keydown.enter="goToSearchPage($event)"
        @keydown.esc="toggleSearchModal"
      />
      <SearchResults
        :visible="isSearchOpen"
        :result="result"
        :value="term"
        @close="toggleSearchModal"
        @removeSearchResults="
          removeSearchResults();
          toggleSearchModal();
        "
        @searchPagination="searchPagination()"
      />
    </div>
    <SfOverlay
      :visible="isSearchModalOpen"
      @click="toggleSearchModal"
      class="search-overlay"
    />
  </div>
</template>

<script>
import { SfOverlay } from '@storefront-ui/vue';
import { useUiHelpers, useUiState, useWindow } from '~/composables';
import {
  useFacet,
  useCategorySearch,
  categoryGetters,
} from '@gemini-vsf/composables';
import {
  defineComponent,
  ref,
  computed,
  watch,
  useContext,
  useRouter,
} from '@nuxtjs/composition-api';
import SearchBarCustom from '~/components/SearchBarCustom.vue';
import SearchResults from '~/components/SearchResults.vue';
import debounce from 'lodash.debounce';
import { CrossIcon } from '~/components/General/Icons';
import { SearchCategoriesToExclude } from '~/assets/SearchCategoriesToExclude';

export default defineComponent({
  name: 'SearchModal',
  components: {
    CrossIcon,
    SearchBarCustom,
    SearchResults,
    SfOverlay,
  },

  setup() {
    const { app } = useContext();
    const { isSearchModalOpen, toggleSearchModal } = useUiState();
    const router = useRouter();
    const { realHeaderHeight, handleBodyClass } = useWindow();
    const modalTopHeight = computed(() => {
      if (window.innerWidth < 1024 || app.$device.isMobileOrTablet) {
        return `top:${realHeaderHeight.value}px`;
      }
      return `top:0`;
    });

    watch(isSearchModalOpen, (newValue) => {
      if (newValue) {
        handleBodyClass('addClass', 'no-scroll');
      } else {
        handleBodyClass('removeClass', 'no-scroll');
      }
    });

    const { getFacetsFromURL } = useUiHelpers();

    const { result: searchResult, search: productsSearch } =
      useFacet('AppHeader:Products');
    const { result: categories, search: categoriesSearch } = useCategorySearch(
      'AppHeader:Categories'
    );

    const term = ref(getFacetsFromURL().term);
    const isSearchOpen = ref(false);
    const result = ref(null);

    const closeSearch = () => {
      if (!result) {
        if (!isSearchOpen.value) return;

        term.value = '';
        isSearchOpen.value = false;
      }
    };

    const removeSearchResults = () => {
      result.value = null;
      term.value = '';
      isSearchOpen.value = false;
    };

    const searchPageNumber = ref(1);

    const searchPagination = async () => {
      searchPageNumber.value += 1;

      handleBodyClass('addClass', 'loading_search');
      await productsSearch({
        itemsPerPage: 20,
        page: searchPageNumber.value,
        term: term.value,
        customQuery: {
          products: 'productListCustom',
        },
      });
      result.value = {
        ...result.value,
        products: [
          ...result.value.products,
          // eslint-disable-next-line no-unsafe-optional-chaining
          ...searchResult.value?.data?.items,
        ],
      };
      handleBodyClass('removeClass', 'loading_search');
    };

    const handleSearch = debounce(async (event) => {
      if (event.code !== 'Enter') {
        term.value = !event.target ? event : event.target.value;

        handleSearch.cancel();
        if (term?.value && term.value.length >= 3 && !event.ctrlKey) {
          handleBodyClass('addClass', 'loading_search');

          await productsSearch({
            itemsPerPage: 20,
            page: 1,
            term: term.value,
            customQuery: {
              products: 'productListCustom',
            },
          });

          const aggregations = searchResult.value?.data?.availableFilters;
          const catsIds = aggregations?.find((aggr) => {
            return aggr?.attribute_code === 'cats_ids';
          });

          const populatedCatsIds = catsIds?.options
            .filter((catId) => {
              return !SearchCategoriesToExclude.has(
                catId.value.split('::').pop()
              );
            })
            .map((catDataPopulated) => {
              return catDataPopulated?.value.split('::').pop();
            });

          const catIdsCount = {};

          catsIds?.options.forEach((catDataPopulated) => {
            catIdsCount[catDataPopulated?.value.split('::').pop()] =
              catDataPopulated.count;
          });

          await categoriesSearch({
            filters: { category_uid: { in: populatedCatsIds } },
          });

          categories.value.sort((a, b) => {
            if (
              catIdsCount?.[a.uid.split('::').pop()] >
              catIdsCount?.[b.uid.split('::').pop()]
            ) {
              return -1;
            }
            if (
              catIdsCount?.[a.uid.split('::').pop()] <
              catIdsCount?.[b.uid.split('::').pop()]
            ) {
              return 1;
            }
            return 0;
          });

          isSearchOpen.value = true;
          result.value = {
            products: searchResult.value?.data?.items,
            categories: categories.value.map((element) =>
              categoryGetters.getCategoryTree(element)
            ),
            total: searchResult.value?.data?.total,
          };
          handleBodyClass('removeClass', 'open_menu');
          handleBodyClass('removeClass', 'loading_search');
        }
      }
    }, 1000);

    const goToSearchPage = debounce(async (event) => {
      term.value = '';
      await router.push(
        `${app.$fixUrlForCurrentLocale('/search?term=')}${
          !event.target ? event : event.target.value
        }`
      );
      toggleSearchModal();
    }, 1000);

    return {
      isSearchModalOpen,
      toggleSearchModal,
      handleSearch,
      closeSearch,
      removeSearchResults,
      result,
      isSearchOpen,
      term,
      modalTopHeight,
      searchPagination,
      goToSearchPage,
    };
  },
});
</script>

<style lang="scss" scoped>
.search-modal {
  position: fixed;
  top: 0;
  background: var(--c-white);
  width: 100%;
  height: 10.625rem;
  z-index: 10;

  @include to-portrait-max {
    height: 100vh;
    width: 100vw;
    z-index: 9999999;
  }

  .search-modal-close-icon {
    position: absolute;
    right: 1.875rem;
    top: 1.875rem;
    @include pointer;

    @include to-portrait-max {
      right: 1.5625rem;
    }
  }

  .search-bar-icon {
    .sf-icon {
      svg {
        width: 1.25rem;
      }
    }
  }
}

@include from-landscape-min {
  .search-modal {
    .sf-search-bar {
      margin: 3.75rem auto;
    }
  }
}
.search-overlay {
  z-index: 2;
}
</style>
