<template>
  <div class="sf-search-bar">
    <input
      class="sf-search-bar__input"
      type="search"
      :value="value"
      v-bind="$attrs"
      :placeholder="$t(placeholder)"
      v-on="listeners"
    />
    <slot name="icon">
      <span v-if="icon" class="search-bar-icon">
        <SfIcon :color="icon.color" :size="icon.size" icon="search" />
      </span>
    </slot>
  </div>
</template>

<script>
import { SfIcon } from '@storefront-ui/vue';

export default {
  name: 'SearchBarCustom',
  components: { SfIcon },
  inheritAttrs: false,
  props: {
    placeholder: {
      type: String,
      default: 'Search',
    },
    value: {
      type: [Number, String],
      default: null,
    },
    icon: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    listeners() {
      return {
        // eslint-disable-next-line vue/no-deprecated-dollar-listeners-api
        ...this.$listeners,
        'keydown.enter': (event) =>
          this.$emit('keydown.enter', event.target.value),
        'keyup.esc': () => this.$emit('input', ''),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.sf-search-bar {
  width: 100%;
  max-width: 970px;
  border: none;

  .search-bar-icon {
    position: absolute;
    left: 0.9375rem;
  }

  .sf-search-bar__input {
    padding-left: 3.125rem;
    border: var(--black-border);
    border-radius: 3px;
    height: 3.125rem;

    &:focus-visible,
    &:focus {
      outline: none;
    }
  }
}

@include to-portrait-max {
  .sf-search-bar {
    display: flex;
    width: auto;
    margin: 6.25rem 1.5625rem var(--space-xxs) 1.5625rem;
    padding-bottom: 1.5625rem;
  }
}
</style>

<style lang="scss">
@import '@storefront-ui/shared/styles/components/molecules/SfSearchBar.scss';

.sf-search-bar {
  .search-bar-icon {
    .sf-icon {
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}
</style>
