<template>
  <div class="top-bar" :class="checkSticky && 'header--sticky'">
    <div class="top-bar__content">
      <div class="language-switcher" @click="toggleCountrySidebar">
        <span> {{ marketDetails }} </span>
      </div>
      <div v-if="topBarContent && !loadingBlocks" class="message">
        <p>
          {{ topBarContent.text }}
        </p>
      </div>
      <div v-else />
      <div class="support-link">
        <SfLink
          v-if="topBarContent && topBarContent.hasOwnProperty('link')"
          :link="$fixUrlForCurrentLocale(topBarContent.link.url || '/')"
          :target="topBarContent.link.target || '_self'"
          :key="`topBar-${topBarContent.link.label || 'link'}`"
        >
          {{ topBarContent.link.label }}
        </SfLink>
        <SfButton
          v-e2e="'app-header-account'"
          class="sf-button--pure sf-header__action"
          data-testid="accountIcon"
          aria-label="Account"
          @click="handleAccountClick"
        >
          <p v-if="isAuthenticated">{{ `${$t('Hello,')} ${userName}` }}</p>
          <p v-else>Account</p>
        </SfButton>
      </div>
    </div>
  </div>
</template>

<script>
import { SfLink, SfButton } from '@storefront-ui/vue';
import {
  ref,
  useFetch,
  computed,
  useRoute,
  useRouter,
  useContext,
  watch,
  shallowRef,
} from '@nuxtjs/composition-api';
import { contentGetters, useContent, useUser } from '@gemini-vsf/composables';
import { useI18n } from '~/helpers/hooks/usei18n';
import { useUiState } from '~/composables';

export default {
  components: {
    SfLink,
    SfButton,
  },
  props: {
    checkSticky: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const {
      $vsf: {
        $gemini: { config },
      },
    } = useContext();
    const { toggleCountrySidebar } = useUiState();
    const router = useRouter();
    const { app } = useContext();
    const { loading: loadingUser, isAuthenticated, user } = useUser();
    const { toggleLoginModal } = useUiState();
    const route = useRoute();
    const userName = ref(user.value?.firstname);

    // Watch as user data load to print name on SSR
    watch(user, () => {
      if (user.value?.firstname) {
        userName.value = user.value?.firstname;
      }
    });

    const handleAccountClick = async () => {
      if (isAuthenticated.value) {
        await router.push(app.$fixUrlForCurrentLocale('/my-account'));
      } else {
        toggleLoginModal();
      }
    };

    const { locale } = useI18n();
    const { blocks, loadBlocks } = useContent(`topBar`);
    const topBarSelectors = [`top-bar`, 'markets-association'];
    const topBarContent = ref();
    const marketsAssociation = ref();
    const validGroup = shallowRef();
    const userStatus = computed(() => {
      return !loadingUser.value && !isAuthenticated.value ? 'guest' : 'logged';
    });
    const loadingBlocks = shallowRef(true);

    const setValidGroup = (blockData) => {
      marketsAssociation.value = blockData?.[topBarSelectors[1]] || {};
      if (marketsAssociation.value) {
        Object.keys(marketsAssociation.value).forEach((group) => {
          validGroup.value =
            group !== 'valid_segments' &&
            marketsAssociation.value?.[group]?.indexOf(
              config?.localesToMarkets?.[locale].split('|')[0]
            ) !== -1
              ? group
              : 'group_0';
        });
      }
    };

    const populateBlockData = (blockData) => {
      try {
        const topLevelData =
          blockData[topBarSelectors[0]][
            // fallback to group_0 if there's no validGroup
            // eslint-disable-next-line no-prototype-builtins
            blockData[topBarSelectors[0]]?.hasOwnProperty(validGroup.value)
              ? validGroup.value
              : 'group_0'
          ][
            // Always use guest data if logged has no value
            blockData[topBarSelectors[0]][validGroup.value]?.logged?.active
              ? userStatus.value
              : 'guest'
          ];
        topBarContent.value =
          topLevelData.data?.it?.active && locale === 'it'
            ? topLevelData.data.it
            : topLevelData.data;
        topBarContent.value.text =
          topBarContent.value?.text ?? 'Express Shipping';
      } catch {
        topBarContent.value = {
          text: 'Express Shipping',
          link: {
            label: locale === 'it' ? 'Supporto' : 'Support',
            target: '_self',
            url: locale === 'it' ? '/contatti' : '/contacts',
          },
        };
      }
    };

    useFetch(async () => {
      await loadBlocks({ identifiers: topBarSelectors });
      const blockData = await contentGetters.getCmsBlockContent(blocks.value);
      try {
        // Check all markets array to find in which group the current market is contained
        setValidGroup(blockData);
        if (validGroup.value) {
          populateBlockData(blockData);
        }
        if (process.client) {
          loadingBlocks.value = false;
        } else if (process.server) {
          loadingBlocks.value = true;
        }
      } catch {
        topBarContent.value = {
          text: 'Express Shipping',
          link: {
            label: locale === 'it' ? 'Supporto' : 'Support',
            target: '_self',
            url: locale === 'it' ? '/contatti' : '/contacts',
          },
        };
        console.warn('Unable to fetch block content.');
      }
    });

    watch(loadingUser, async () => {
      if (!loadingUser.value) {
        await loadBlocks({ identifiers: topBarSelectors });
        const ssrBlockData = await contentGetters.getCmsBlockContent(
          blocks.value
        );
        setValidGroup(ssrBlockData);
        populateBlockData(ssrBlockData);
        loadingBlocks.value = false;
      }
    });

    const marketDetails = computed(() => {
      try {
        return route.value?.fullPath?.split('/')?.[1]?.includes('it')
          ? 'IT - Italiano'
          : `${locale.toUpperCase()} - English`;
      } catch (error) {
        console.error('could not update language switcher', error);
      }
      return 'IT - Italiano';
    });

    return {
      topBarContent,
      toggleCountrySidebar,
      marketDetails,
      handleAccountClick,
      isAuthenticated,
      userName,
      loadingBlocks,
    };
  },
};
</script>

<style lang="scss" scoped>
.top-bar {
  @include font-12x16;
  padding-top: 0.438rem;
  padding-bottom: 0.438rem;
  position: relative;
  z-index: 2;
  color: var(--c-grey-2);
  background: var(--c-super-light-grey);
  transition: all 0.1s;
  width: 100%;
  top: 0;
  p,
  a,
  button {
    color: var(--c-grey-2);
    margin: 0;
    @include font-12x16;
  }
  a,
  button {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
    &:before {
      display: none;
    }
  }
  &__content {
    .message {
      text-align: center;
    }
    .language-switcher,
    .support-link {
      display: none;
    }
  }
}
//desktop
@include from-landscape-min {
  .top-bar {
    &__content {
      display: grid;
      grid-template-columns: 2fr 3fr 2fr;
      grid-template-rows: 1fr;
      grid-column-gap: 0px;
      grid-row-gap: 0px;
      margin: auto;
      max-width: var(--desktop-min);
      padding-left: 2.188rem;
      padding-right: 2.188rem;
      .language-switcher,
      .support-link {
        display: block;
        :nth-child(1) {
          padding-right: 1.875rem;
        }
        @include pointer;
      }
      .support-link {
        text-align: right;
      }
    }
  }
}
</style>
