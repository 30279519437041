<template>
  <div>
    <HvMegaMenu
      :visible="isSearchOpen"
      :title="$t('Search results')"
      class="search"
    >
      <transition name="sf-fade" mode="out-in">
        <div
          v-if="products && products.length > 0"
          key="results"
          class="search__wrapper-results"
        >
          <div class="results-listing" v-if="!menusLoading">
            <div class="categories-search-results">
              <ul class="categories-search-results__primary-categories">
                <li v-for="(menuItem, index) in mainMenu.items" :key="index">
                  <SfLink
                    :link="$fixUrlForCurrentLocale(menuItem.link)"
                    @click.native="closeSearchResults"
                  >
                    {{ menuItem.label }}
                  </SfLink>
                </li>
              </ul>
              <ul
                v-if="brands && brands.length > 0"
                class="categories-search-results__secondary-categories brands-list"
                :class="brands.length > 5 && 'brand-list-scrolling'"
              >
                <li class="list-categories-title">
                  <span>
                    {{ 'Brand' }}
                  </span>
                </li>
                <li v-for="(brand, index) in brands.slice(0, 30)" :key="index">
                  <SfLink
                    :link="$fixUrlForCurrentLocale(brand.slug)"
                    @click.native="closeSearchResults"
                  >
                    {{ brand.label }}
                  </SfLink>
                </li>
              </ul>
              <ul
                v-if="categories && categories.length > 0"
                class="categories-search-results__secondary-categories"
                :class="categories.length > 5 && 'category-list-scrolling'"
              >
                <li class="list-categories-title">
                  <span>
                    {{ $t('Categories') }}
                  </span>
                </li>
                <li
                  v-for="(category, index) in categories.slice(0, 30)"
                  :key="index"
                >
                  <SfLink
                    :link="$fixUrlForCurrentLocale(category.slug)"
                    @click.native="closeSearchResults"
                  >
                    {{ category.label }}
                  </SfLink>
                </li>
              </ul>
            </div>
            <div class="products-search-results">
              <div class="products-search-results__title">
                <span>{{ $t('Results ') + '(' + totalResults + ')' }} </span>
              </div>
              <ProductsGrid
                id="search-products-grid"
                v-if="!loading && products.length > 0"
                :products-list="products"
                @click.native="closeSearchResults()"
                :component-key="`search-grid-${Date.now()}`"
              />
              <div v-if="!isPaginationDisable" class="load-more-button">
                <CustomButton
                  id="more-products"
                  :text="$t('Load More')"
                  @click="
                    () => {
                      $emit('searchPagination');
                      paginationCounter += 1;
                    }
                  "
                  :disabled="loading"
                ></CustomButton>
              </div>
              <div v-else class="no-more-result">
                <span>{{ $t('All products loaded') }}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-else key="no-results" class="no-results-wrapper">
          <p class="before-results__paragraph">
            {{ $t('Sorry, there are no results that match your search.') }}
          </p>
          <div class="search__wrapper-results">
            <div class="results-listing">
              <div class="categories-search-results">
                <ul class="categories-search-results__primary-categories">
                  <li v-for="(menuItem, index) in mainMenu.items" :key="index">
                    <SfLink
                      :link="$fixUrlForCurrentLocale(menuItem.link)"
                      @click.native="closeSearchResults"
                    >
                      {{ menuItem.label }}
                    </SfLink>
                  </li>
                </ul>
                <ul class="categories-search-results__secondary-categories">
                  <li
                    v-for="(menuItem, index) in searchMenu.items"
                    :key="index"
                    class="search-menu-list-item"
                  >
                    <SfLink
                      class="search-menu-link"
                      :link="menuItem.link"
                      @click.native="closeSearchResults"
                    >
                      <div class="header-navigation__linkFeat">
                        {{ menuItem.label.split('---')[0]
                        }}<span
                          class="header-navigation__linkFeat__label"
                          v-if="menuItem.label.split('---')[1]"
                          >{{ menuItem.label.split('---')[1] }}</span
                        >
                      </div>
                    </SfLink>
                  </li>
                </ul>
              </div>
              <div class="products-search-results">
                <div class="products-search-results__title">
                  <span>{{ $t('Trending products') }} </span>
                </div>
                <ProductsGrid
                  :page-size="4"
                  @click.native="closeSearchResults()"
                  class="trending-products-grid"
                />
              </div>
            </div>
          </div>
        </div>
      </transition>
    </HvMegaMenu>
  </div>
</template>

<script>
import { SfLink } from '@storefront-ui/vue';
import {
  ref,
  watch,
  computed,
  defineComponent,
  useFetch,
} from '@nuxtjs/composition-api';
import { productGetters, useUser, useMenu } from '@gemini-vsf/composables';
import { useUiHelpers } from '~/composables';
import HvMegaMenu from '~/components/Search/HvMegaMenu.vue';
import { addBasePath } from '~/helpers/addBasePath';
import ProductsGrid from '~/components/Catalog/ProductsGrid.vue';
import { CustomButton } from './General';

export default defineComponent({
  name: 'SearchResults',
  components: {
    HvMegaMenu,
    SfLink,
    ProductsGrid,
    CustomButton,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    result: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: [Number, String],
      default: null,
    },
    paginationSize: {
      type: Number,
      default: 20,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { isAuthenticated } = useUser();

    const th = useUiHelpers();
    const isSearchOpen = ref(props.visible);
    const products = computed(() => {
      const res = props.result;
      return res?.products;
    });

    const menusLoading = ref(true);

    const categories = computed(() => {
      const res = props.result;
      const cats = res?.categories?.filter((c) => !c.slug.includes('designer'));
      cats?.map((d) => {
        const gender = d.slug.split('/')[1];
        const genderUp = `${gender.charAt(0).toUpperCase()}${gender.slice(1)}`;
        if (genderUp && !d.label.includes('/')) {
          d.label = `${genderUp} / ${d.label}`;
        }
        return true;
      });
      return cats;
    });

    const brands = computed(() => {
      const res = props.result;
      const designer = res?.categories?.filter((c) =>
        c.slug.includes('designer')
      );
      designer?.map((d) => {
        const gender = d.slug.split('/')[1];
        const genderUp = `${gender.charAt(0).toUpperCase()}${gender.slice(1)}`;
        if (genderUp && !d.label.includes('/')) {
          d.label = `${genderUp} / ${d.label}`;
        }
        return true;
      });
      return designer;
    });

    const totalResults = computed(() => {
      const res = props.result;
      return res?.total;
    });

    const paginationCounter = ref(1);
    const isPaginationDisable = computed(
      () => paginationCounter.value * props.paginationSize >= totalResults.value
    );

    const { menu: mainMenu, search: searchMainMenu } = useMenu('main');
    const { menu: searchMenu, search: searchSearchMenu } = useMenu('search');

    useFetch(async () => {
      await searchMainMenu({ code: 'mainmenu' });
      await searchSearchMenu({ code: 'search-menu' });
      menusLoading.value = false;
    });

    watch(
      () => props.visible,
      (newVal) => {
        isSearchOpen.value = newVal;
        if (isSearchOpen.value) {
          document.body.classList.add('no-scroll');
        } else {
          document.body.classList.remove('no-scroll');
          emit('removeSearchResults');
        }
      }
    );

    const closeSearchResults = () => {
      emit('removeSearchResults');
    };

    return {
      th,
      isSearchOpen,
      productGetters,
      products,
      categories,
      brands,
      isAuthenticated,
      closeSearchResults,
      addBasePath,
      mainMenu,
      searchMenu,
      totalResults,
      paginationCounter,
      isPaginationDisable,
      menusLoading,
    };
  },
});
</script>

<style lang="scss" scoped>
.brand-list-scrolling,
.category-list-scrolling {
  @include from-landscape-min {
    height: 175px;
    width: 200px;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 2px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: black;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: black;
    }
  }
}

.btn-close-search-result {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  @include for-mobile {
    top: 24px;
    right: 16px;
  }
}

.search {
  position: absolute;
  right: 0;
  left: 0;
  z-index: 3;
  --mega-menu-column-header-margin: var(--spacer-sm) 0 var(--spacer-xl);
  --mega-menu-height: auto;

  .results-listing {
    max-width: var(--desktop-min);
    margin: auto;
    .products-search-results {
      .products-search-results__title {
        margin-bottom: var(--space-xs);

        span {
          @include font-14x16;
        }

        @include to-portrait-max {
          padding-left: 1.5625rem;
          margin-top: var(--space-s);
        }
      }
      .load-more-button {
        margin-top: 3.75rem;
        margin-bottom: 6.25rem;

        #more-products {
          background-color: white;
          color: #ffffff;
          border: 1px solid black;
          color: black;
          margin: auto;
          max-width: 170px;
        }
      }
      .no-more-result {
        margin-top: 3.75rem;
        margin-bottom: 6.25rem;
        text-align: center;
        span {
          @include font-16x19;
          text-align: center;
          text-transform: uppercase;
        }
      }
    }
    .categories-search-results {
      ul {
        list-style-type: none;
        padding-left: unset;

        @include to-portrait-max {
          display: flex;
          gap: var(--space-xs);
          border-bottom: 1px solid var(--c-light-grey);
          padding: 0.9375rem 1.5625rem;
          margin: 0;
        }

        li {
          margin-bottom: 0.75rem;
          a {
            text-decoration: none;
            &:hover {
              text-decoration: underline;
            }
          }

          @include to-portrait-max {
            margin-bottom: 0;
            min-width: max-content;
          }
        }
      }
      &__primary-categories {
        margin-top: 0;
        li {
          a {
            @include font-14x16-300;
            text-transform: uppercase;
          }
        }
      }
      &__secondary-categories {
        span {
          @include font-14x16-300;
        }
        li {
          a,
          span {
            @include font-14x16-300;
          }
        }
        .list-categories-title {
          span {
            @include font-14x16;
          }
        }

        @include to-portrait-max {
          overflow-x: auto;
          overflow-y: hidden;
        }
        .search-menu-list-item {
          width: max-content;
          &:hover {
            border-bottom: 1px solid black;
          }
        }
        .search-menu-link {
          &:hover {
            text-decoration: none;
          }
        }
        .header-navigation__linkFeat {
          @include font-14x16;

          &__label {
            @include font-12x16;
            margin-left: 0.375rem;
            color: var(--c-grey-2);
          }
        }

        &.brands-list {
          @include from-landscape-min {
            margin-bottom: 3.125rem;
          }
        }
      }
      span {
        @include font-14x16;
        padding-bottom: 1.0625rem;
      }
    }
  }
}
.results {
  &--mobile {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    background: var(--c-white);
    padding: var(--spacer-base) var(--spacer-sm);
    --product-card-max-width: 9rem;
  }
}

.before-results {
  box-sizing: border-box;
  padding: var(--spacer-base) var(--spacer-sm) var(--spacer-2xl);
  width: 100%;
  text-align: center;
  @include for-desktop {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }
  &__picture {
    --image-width: 230px;
    margin-top: var(--spacer-2xl);
    @include for-desktop {
      --image-width: 18.75rem;
      margin-top: var(--spacer-base);
    }
  }
  &__paragraph {
    @include font-16x19;
    margin: 0 auto;
    margin-bottom: 1.25rem;
    max-width: max-content;

    @include from-landscape-min {
      margin-bottom: 3.75rem;
    }
    @include to-portrait-max {
      padding-left: 1.5625rem;
      padding-right: 1.5625rem;
    }
  }
  &__button {
    margin: var(--spacer-xl) auto;
    width: 100%;
  }
}

.search__wrapper-results {
  width: 100%;
  border-top: 1px solid var(--c-light-grey);
}

@include from-landscape-min {
  .results-listing {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;

    .categories-search-results {
      border-right: 1px solid var(--c-light-grey);
      padding-left: 6.125rem;
      padding-top: 3.125rem;
      &__primary-categories {
        margin-top: 0;
        margin-bottom: 3.125rem;
      }
    }
    .products-search-results {
      padding-top: 3.125rem;
      padding-left: 6.125rem;
      padding-right: 6.125rem;
    }
  }
}
</style>

<style lang="scss">
.search {
  .sf-mega-menu__menu {
    display: block;
  }
}
.sf-mega-menu {
  display: block;

  @include for-mobile {
    height: 100vh !important;
    overflow-y: scroll;
    padding-bottom: 15.625rem;
  }

  &.search {
    @include for-desktop {
      height: 100vh;
      padding-bottom: 9.375rem;
      overflow-y: scroll;

      .sf-scrollable__content {
        max-height: unset;
      }
    }
    .sf-mega-menu__content {
      max-width: 100%;
      padding: 0;
    }
    .sf-mega-menu__bar {
      display: none;
    }

    .products-grid {
      grid-column-gap: 0.3125rem;
      @include from-landscape-min {
        grid-column-gap: var(--space-xxs);
      }

      .product-card {
        border: none;
        // margin-bottom: 2.1875rem;

        @include from-landscape-min {
          margin-bottom: 3.125rem;
        }

        .product-card__detail {
          display: block;

          @include from-landscape-min {
            padding-left: 0;
            padding-right: 0;
          }

          .product-card__detail__name {
            margin-bottom: 0.3125rem;
          }
          .product-card__detail__brand {
            margin-bottom: 0.1875rem;

            @include to-portrait-max {
              margin-top: 0.625rem;
            }
          }
        }
        .product-card__wishlist,
        .product-card__ruler,
        .product-card__plus {
          display: none;
        }
      }
    }
    #search-products-grid {
      border: none;
      .product-card {
        border: none;
      }
    }
  }

  .search__categories {
    @include for-desktop {
      display: none;
    }
    @include for-mobile {
      flex: auto;
      .sf-mega-menu-column__content {
        display: none;
      }
    }
  }
}

.products-search-results {
  .trending-products-grid {
    .product-card {
      border-bottom: unset !important;
    }
  }
}

@include to-portrait-max {
  .sf-mega-menu.search {
    .products-grid {
      .product-card {
        .product-card__detail {
          height: min-content;
          padding-bottom: 0.625rem;
        }
      }
    }
  }
}
</style>
